import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined } from '../../helpers';

export const valueWhitelistedValidator: <T>(whitelist: T[]) => ValidatorFn = (whitelist) => {
    return (control: AbstractControl): ValidationErrors | null => {
        return whitelist.find((ele) => ele === control.value) ? null : { valueNotInList: true };
    };
};

export const optionalValueWhitelistedValidator: <T>(whitelist: T[]) => ValidatorFn = (whitelist) => {
    return (control: AbstractControl): ValidationErrors | null => {
        return isNullOrUndefined(control.value) || control.value === ''
            ? null
            : valueWhitelistedValidator(whitelist)(control);
    };
};
