export interface Task {
    taskId: string;
    status: TaskStatus;
    endDate: number;
    taskType: TaskType;
    taskCreateType: TaskCreateType;
    itemType: ItemType;
    transmitDate?: number;
    targetQuantity?: number;
    currentQuantity?: number;
    employee?: string;
}

export enum TaskStatus {
    // below three statuses should not be handled on the frontend side (we do not use them to show task pilot information in operation cards)
    create = 'create',
    delete = 'delete',
    timeout = 'timeout',
    // below three status are used for to show task pilot information in operation cards
    active = 'active', // angefragt
    close_ok = 'close_ok', // erledigt
    close_timeout = 'close_timeout', // timeout
}

export enum TaskType {
    input = 'input',
    output = 'output',
}

export enum TaskCreateType {
    manual = 'manual',
    automatic = 'automatic',
    new = 'new',
}

export enum ItemType {
    Paper = 'Paper',
    Plate = 'Plate',
    Ink = 'Ink',
    AniloxRoller = 'AniloxRoller',
    PlateTrolley = 'PlateTrolley',
    Tool = 'Tool',
    Semifinish = 'Semifinish',
    Others = 'Others',
    Consumables = 'Consumables',
    Varnish = 'Varnish',
    Coating = 'Coating',
    GeneralOperationInfo = 'GeneralOperationInfo',
}
