import { Entity } from './common';
import { CaptureMode, MachineClass } from './machine';
import { Consumption } from './consumption.model';

export interface TimeModeEntry extends Entity {
    email: string;
    shiftStart: string;
    deviceId: string;
    duration: number;
    startTime?: number;
    endTime?: number;
    status: TimeModeStatus;
    jobId?: string;
    activity: string;
    type: CaptureMode;
    goodAmount?: number;
    wasteAmount?: number;
    consumptions: Consumption[];
    workstepId?: string;
    submissionId: string;
    comment?: string;
}

export enum TimeModeStatus {
    DRAFT = 'Draft',
    PENDING = 'Pending',
    SUBMITTED = 'Submitted',
    ERROR = 'Error',
}

export interface FarewellScreenRequestItem {
    deviceId: string;
    shiftStart: string;
}

export interface ReportSummaryData {
    machineId: string;
    machineName: string;
    machineClass: MachineClass;
    loggedInTime: number;
    productionTime: number;
    otherTimes: number;
    operationCount: number;
}
