import { AssistantsSignOff, DataTerminalError, OperationActionSignOff, Permission } from '@data-terminal/shared-models';
import { Role } from './role';

export interface UserMachines {
    allMachines: Machine[];
    assignedMachines: Machine[];
    serviceError: DataTerminalError;
    preselectedMachines: Machine[];
    availableAssistants: AvailableAssistants[];
}

export interface Machine {
    organizationId: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    machineId: string;
    machineName: string;
    machineClass: MachineClass;
    connectType: string;
    defSetupActivityId: string;
    defProdActivity: string;
    defAssistantActivityId: string;
    lastKeepAlive: number;
    runningOperation?: RunningOperation;
    isCounterBox: boolean;
    captureMode: CaptureMode;
    signedOnUsers: SignedOnUser[];
    lastActiveDate: number;
}

export enum CaptureMode {
    START_STOP = 'START_STOP',
    TIME = 'TIME',
    LOGGED_IN = 'LOGGED_IN',
}

export interface RunningOperation {
    primaryKey: string;
    opName?: string;
    plannedGood?: number;
    actualGood?: number;
    activityName: string;
    progressPercent?: number;
}

export interface LoginTypeAndMachineId {
    machineId: string;
    loginTyp: LoginType | null;
    withAssistantIds?: string[];
    timestamp?: number;
}

export interface SignedOnUser {
    firstName: string;
    lastName: string;
    userId: string;
    role: Role;
    captain: boolean;
    userMachinePermissions: Permission[];
    inactiveSince?: number;
    timestamp?: number;
    zaikioId?: string;
}

export enum MachineListActionType {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

export enum LoginType {
    TAKE_OVER = 'TAKE_OVER',
    JOIN = 'JOIN',
}

export interface MachineListAction {
    payload: Machine;
    action: MachineListActionType;
}

export interface SignOffMachine {
    machine: Machine;
    machineId: string;
    assistantsSignOff?: AssistantsSignOff;
    runningOperation?: string;
    operationAction?: OperationActionSignOff;
    newCaptain?: string;
    goodCount?: number;
    wasteCount?: number;
    comment?: string;
    isAssistant: boolean;
}

export interface SignOffList {
    userId: string;
    signofflist: SignOffMachine[];
}

export enum MachineClass {
    ID_ADHESIVEBINDER = 'ID_AdhesiveBinder',
    ID_ADHESIVEBINDERLINE = 'ID_AdhesiveBinderLine',
    ID_BOXMAKER = 'ID_BoxMaker',
    ID_BOOKPRODUCTIONLINE = 'ID_BookProductionLine',
    ID_BOXPACKER = 'ID_BoxPacker',
    ID_CUTTER = 'ID_Cutter',
    ID_CASEMAKER = 'ID_CaseMaker',
    ID_DIGITALPRINTER = 'ID_DigitalPrinter',
    ID_MANUALPREPRESS = 'ID_ManualPrePress',
    ID_MANUALPRESS = 'ID_ManualPress',
    ID_POSTPRESSEXTERNAL = 'ID_PostPressExternal',
    ID_MANUALPOSTPRESS = 'ID_ManualPostPress',
    ID_GATHERER = 'ID_Gatherer',
    ID_SHEETFEDPRESS = 'ID_SheetfedPress',
    ID_SHAPECUTTER = 'ID_ShapeCutter',
    ID_SHEETFOLDER = 'ID_SheetFolder',
    ID_STITCHER = 'ID_Stitcher',
    ID_THREADSEWER = 'ID_ThreadSewer',
    ID_TRIMMER = 'ID_Trimmer',
    DEFAULT = 'default',
}

export interface AvailableAssistants {
    machineId: string;
    users: AvailableAssistantsUser[];
}

export interface AvailableAssistantsUser {
    userId: string;
    firstName: string;
    familyName: string;
}

export interface SetAssistantsBody {
    userId: string;
    entries: SetAssistantsEntry[];
    locale: string;
}

export interface SetAssistantsEntry {
    machineId: string;
    userIds: string[];
}
